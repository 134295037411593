// TODO: refacto alert with existing common component (in other projects)
export const ALERT_MESSAGE = { success: 'Données sauvegardées', error: 'Une erreur est survenue' }
export const ALERT_SEVERITY = { error: 'error', success: 'success', warning: 'warning' }
export const DEFAULT_SUCCESS_ALERT = {
  message: ALERT_MESSAGE.success,
  open: true,
  severity: ALERT_SEVERITY.success,
}

export const CONTENTIOUS_STATUS = {
  gest: 1,
  precon: 2,
  cont: 3,
}

export const DISTRIBUTOR_STATUS = {
  toComplete: 1,
  completed: 2,
  toValidate: 3,
  validated: 4,
}

export const ERRORS = {
  updateDiscount: 1,
  updateFileDates: 2,
  missingSalesPoint: 3,
  addFile: 4,
  salesPointAttachment: 5,
  addFileProduct: 6,
}

export const FILE_TARGET = {
  excelAll: 'xlsx_all',
  excelAmlStop: 'xlsx_aml_stop',
  excelError: 'xlsx_error',
  ksiopAML: 'ksiop_aml',
  ksiopPAD: 'ksiop_pad',
  ksiopFbPAD: 'ksiop_fb_pad',
  excelEmailsDistributors: 'xlsx_emails_distributor',
  excelFbPAD: 'xlsx_fb_pad',
}

export const FILE_ACTIONS = {
  addProduct: 0,
  addFile: 1,
  updateDiscount: 2,
  updateDates: 3,
}

export const FB_PAD_ACTIONS = {
  addFile: 0,
  updateFile: 1,
  validateError: 2,
  declineError: 3,
}

export const MILESTONES = ['AMACLO', 'OPPAMAC', 'RATAMAC', 'RUPAPP', 'RESTR', 'TER']

export const SALESPOINT_ACTIONS = {
  addSalesPoint: 0,
  updateDistributor: 1,
}

export const SALESPOINT_STATUS = {
  completed: 'Complet',
  toComplete: 'A compléter',
}

export const SERVICES = {
  AMA: 'AMA',
  AML: 'AML',
  PAD: 'PAD',
  PADLeader: 'PAD Heineken Leader',
  PADHKOnly: 'PAD Heineken Seul',
}

export const SERVICES_ID = {
  AML: 1,
  AMA: 2,
  PAD: 5,
}

export const USER_ROLES = {
  admin: 1,
  manager: 2,
  commercial: 3,
}

export const ENTITY = {
  HE: 'HE',
  FB: 'FB',
}

export const CODE_LABEL = 'Code'
export const DISTRIBUTOR_LABEL = 'Distributeur'
export const STATUS_LABEL = 'Statut'
export const AM_VOLUME_LABEL = 'Volume AMA/AML (en HL)'
export const PAD_VOLUME_LABEL = 'Volume PAD (en HL)'
export const SALESPOINT_NUMBER_LABEL = 'Nombre de points de vente'
export const AMOUNT_LABEL = 'Montant AML/AMA T123 déjà versé'

export const VALIDATION_RATE_HE = 'Avancement validation HE'
export const VALIDATION_RATE_FB = 'Avancement validation FB'
export const FOLDERS_NUMBER = 'Nb de dossiers'
