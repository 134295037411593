import { Grid, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import API from '../../../api/api'
import { addFbPadLine, updateFbLine } from '../../../api/mutations/fileMutations'
import { productsQuery } from '../../../api/queries/productQueries'
import { FB_PAD_ACTIONS } from '../../../utils/constants'
import DialogForm from '../DialogForm'
import Select from '../SelectForm'
import CommonTextField from '../TextFieldForm'
import { getDisabled } from './functions'

export default function FbPadForm({ action, open, selectedFile, file, title, onClose, onValidate }) {
  const initialBooleanDataState = {
    category: false,
    product: false,
    discount_HE: false,
    discount_FB: false,
    free_HE: false,
    free_FB: false,
    volume: false,
    taxRate: false,
  }

  const [disabled, setDisabled] = useState(initialBooleanDataState)
  const [displayError, setDisplayError] = useState(false)
  const [data, setData] = useState({
    discount_HE: action === FB_PAD_ACTIONS.updateFile ? selectedFile.discount_HE : 0,
    discount_FB: action === FB_PAD_ACTIONS.updateFile ? selectedFile.discount_FB : 0,
    free_HE: action === FB_PAD_ACTIONS.updateFile ? selectedFile.free_HE : 0,
    free_FB: action === FB_PAD_ACTIONS.updateFile ? selectedFile.free_FB : 0,
    volume: action === FB_PAD_ACTIONS.updateFile ? selectedFile.volume : 0,
    category: action === FB_PAD_ACTIONS.addFile ? 0 : selectedFile?.product?.category?.id ?? 0,
    product: action === FB_PAD_ACTIONS.addFile ? 0 : selectedFile?.product?.id ?? 0,
    taxRate: action === FB_PAD_ACTIONS.addFile ? 0 : selectedFile.tax_rate ?? 0,
  })
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])

  useEffect(() => {
    async function initForm() {
      let keys = []
      if (action === FB_PAD_ACTIONS.updateFile) keys = ['volume', 'discount_HE', 'discount_FB', 'free_HE', 'free_FB']
      else if (action === FB_PAD_ACTIONS.addFile)
        keys = ['category', 'product', 'discount_HE', 'discount_FB', 'free_HE', 'free_FB', 'volume', 'taxRate']
      setDisabled({ ...disabled, ...getDisabled(disabled, keys) })

      const result = await API.queryAPI(productsQuery)
      setProducts(result.products)
      let categories = [{ id: 0, name: 'Aucune sélection' }]
      for (let product of result.products) {
        if (!categories[product.category.id])
          categories[product.category.id] = { id: product.category.id, name: product.category.name }
      }
      setCategories(categories)
    }
    initForm()
  }, [])

  function _handleChange(name, value) {
    setData(
      name === 'product'
        ? { ...data, [name]: value, taxRate: products[value - 1].rate.value }
        : { ...data, [name]: value },
    )
  }

  async function _validateFile() {
    await API.queryAPI(
      updateFbLine(selectedFile.id, data.volume, data.discount_HE, data.discount_FB, data.free_HE, data.free_FB),
    )
    onValidate()
  }

  async function _validateNewFile() {
    const padLinesProducts = file.fbPadLines.filter((padLine) => padLine.product.id === data.product)

    if (padLinesProducts.length === 0) {
      setDisplayError(false)
      await API.queryAPI(
        addFbPadLine(
          file.id,
          data.product,
          data.volume ?? 0,
          data.discount_HE ?? 0,
          data.discount_FB ?? 0,
          data.free_HE ?? 0,
          data.free_FB ?? 0,
          data.taxRate ?? 0,
        ),
      )
      onValidate()
    }
    setDisplayError(true)
  }

  function _handleValidate() {
    if (action === FB_PAD_ACTIONS.updateFile) _validateFile()
    else if (action === FB_PAD_ACTIONS.addFile) {
      _validateNewFile()
    }
  }

  function payback(includeTax) {
    const volume = data.volume ? parseInt(data.volume) : 0
    const free_HE = data.free_HE ? parseInt(data.free_HE) : 0
    const free_FB = data.free_FB ? parseInt(data.free_FB) : 0
    const discount_HE = data.discount_HE ? parseInt(data.discount_HE) : 0
    const discount_FB = data.discount_FB ? parseInt(data.discount_FB) : 0
    const taxRate = data.taxRate ? parseInt(data.taxRate) : 0
    let payback = (volume - free_HE - free_FB) * (discount_HE + discount_FB)
    if (includeTax) payback *= 1 + taxRate / 100
    return isNaN(payback) ? 0 : payback.toFixed(2)
  }

  function filterAndSortProducts(products, data) {
    return products
      .filter((product) => {
        if (data.category === 0) return true
        return product.category.id === data.category
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  return (
    <DialogForm open={open} onValidate={_handleValidate} onClose={onClose} title={title}>
      <Grid container spacing={2}>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Select
              name="category"
              value={data.category}
              label="Catégorie"
              disabled={disabled.category}
              onChange={(e) => _handleChange(e.target.name, e.target.value)}
              items={categories}
              valueName="id"
              text="name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              name="product"
              value={data.product}
              label="Marque"
              disabled={disabled.product}
              onChange={(e) => _handleChange(e.target.name, e.target.value)}
              items={filterAndSortProducts(products, data)}
              valueName="id"
              text="name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonTextField
              name="discount_HE"
              value={disabled.discount_HE ? selectedFile.discount_HE : data.discount_HE}
              onChange={(e) => _handleChange(e.target.name, e.target.value)}
              label="Remise HE"
              disabled={disabled.discount_HE}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonTextField
              name="discount_FB"
              value={disabled.discount_FB ? selectedFile.discount_FB : data.discount_FB}
              onChange={(e) => _handleChange(e.target.name, e.target.value)}
              label="Remise FB"
              disabled={disabled.discount_FB}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonTextField
              name="free_HE"
              value={disabled.free_HE ? selectedFile.free_HE : data.free_HE}
              onChange={(e) => _handleChange(e.target.name, e.target.value)}
              label="Gratuité HE"
              disabled={disabled.free_HE}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonTextField
              name="free_FB"
              value={disabled.free_FB ? selectedFile.free_FB : data.free_FB}
              onChange={(e) => _handleChange(e.target.name, e.target.value)}
              label="Gratuité FB"
              disabled={disabled.free_FB}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonTextField
              name="volume"
              value={disabled.volume ? selectedFile.volume : data.volume}
              onChange={(e) => _handleChange(e.target.name, e.target.value)}
              label="Volume"
              disabled={disabled.volume}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonTextField name="paybackHT" value={payback(false)} label="Ristourne HT" disabled={true} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonTextField
              name="taxRate"
              value={data.taxRate}
              onChange={(e) => _handleChange(e.target.name, e.target.value)}
              label="Taux de TVA"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonTextField name="paybackTTC" value={payback(true)} label="Ristourne TTC" disabled={true} />
          </Grid>
          {displayError && (
            <Typography style={{ marginLeft: '65%' }} color="error">
              Le produit est déjà présent dans le pad
            </Typography>
          )}
        </Grid>
      </Grid>
    </DialogForm>
  )
}
