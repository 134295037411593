import { Box, Tooltip, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ENTITY } from '../../../utils/constants'
import { formatNumber } from '../../../utils/format'
import DetailsButton from '../../Button/DetailsButton'
import ActionTableLayout from '../../Layout/ActionTableLayout'
import BaseTable from '../BaseTable'

FbFilesTable.propTypes = {
  distributor: PropTypes.object.isRequired,
  entity: PropTypes.string.isRequired,
  selectedFiles: PropTypes.object,
  userId: PropTypes.number.isRequired,
  onMenuClick: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default function FbFilesTable({ title, entity, distributor, userId, selectedFiles, onMenuClick, onSelect }) {
  const fbPadFiles = distributor.fbPadFiles
  const headCells = [
    { id: 'cassiopaeCode', label: 'Code Cassiopae' },
    { id: 'sparcCode', label: 'Code SPARC' },
    { id: 'totemCode', label: 'Code Totem Eta. distrib.' },
    { id: 'salesPoint', label: 'Point de vente' },
    { id: 'service', label: 'Type de PAD' },
    { id: 'status', label: 'Statut' },
    { id: 'discount', label: 'Ristourne TTC' },
    { id: 'action', label: 'Accéder aux dossiers' },
  ]
  const fbPadFilesData = () =>
    fbPadFiles
      .filter((fbPadFile) => {
        if (entity === ENTITY.HE) return fbPadFile.service.indexOf('Heineken') !== -1 ? true : false
        if (entity === ENTITY.FB) return fbPadFile.service.indexOf('France Boissons') !== -1 ? true : false
      })
      .map((fbPadFile) => {
        const discount = fbPadFile.fbPadLines.reduce((fileAcc, fileCur) => {
          const paybackHT =
            (fileCur.volume - fileCur.free_HE - fileCur.free_FB) * (fileCur.discount_HE + fileCur.discount_FB)
          return fileAcc + paybackHT * (1 + fileCur.tax_rate / 100)
        }, 0)

        const hasUncheckedError = fbPadFile.fbPadLines.some((cur) => cur.lineStatus === 2)

        return {
          id: fbPadFile.id,
          cassiopaeCode: fbPadFile.cassiopaeCode,
          sparcCode: fbPadFile.sparcCode,
          totemCode: fbPadFile.totemCode,
          nbSalespoint: fbPadFile.nbSalespoint,
          salesPoint: fbPadFile.salespointName,
          service: fbPadFile.service,
          status: hasUncheckedError ? 'Écart à valider' : fbPadFile.status ? 'Validé' : 'À vérifier',
          discount: formatNumber(discount),
          error: fbPadFile.error?.name ?? null,
        }
      })

  const customCells = (cell, currentItem, _index, arrayData) => {
    if (cell === 'action')
      return (
        <DetailsButton to={`/fb-files/${currentItem.id}/${userId}`} data={{ ...distributor, fbPadFiles: arrayData }} />
      )
    if (cell === 'totemCode') {
      return (
        <Tooltip
          title={`${currentItem.nbSalespoint} ${
            currentItem.nbSalespoint >= 2 ? 'établissements distribués' : 'établissement distribué'
          }`}
          placement="top"
          arrow
        >
          <Typography variant="body2">{currentItem.totemCode}</Typography>
        </Tooltip>
      )
    }
  }

  return (
    <Box>
      <ActionTableLayout menuItems={null} title={title} onMenuClick={onMenuClick}>
        <BaseTable
          customCells={customCells}
          defaultOrderBy="cassiopaeCode"
          headCells={headCells}
          selectedRowId={selectedFiles?.id ?? null}
          tableData={fbPadFilesData()}
          onSelect={(item) => onSelect(fbPadFiles.find((current) => current.id === item.id))}
        />
      </ActionTableLayout>
    </Box>
  )
}
