import { Grid } from '@material-ui/core'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import API from '../../../api/api'
import { addFile, updateDatesFileByIds, updateDiscountFileByIds } from '../../../api/mutations/fileMutations'
import { servicesQuery } from '../../../api/queries/serviceQueries'
import { ERRORS, FILE_ACTIONS, SERVICES, SERVICES_ID } from '../../../utils/constants'
import WarningMessage from '../../WarningMessage'
import AlertForm from '../AlertForm'
import DatePicker from '../DatePickerForm'
import DialogForm from '../DialogForm'
import Select from '../SelectForm'
import CommonTextField from '../TextFieldForm'
import {
  checkProductForSelectedDates,
  getDisabled,
  getFilesIds,
  getFormatedDate,
  getProducts,
  getUpdatedFiles,
} from './functions'

FileForm.propTypes = {
  action: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  salesPoint: PropTypes.object.isRequired,
  selectedFile: PropTypes.object,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
}

export default function FileForm({ action, open, selectedFile, salesPoint, title, onClose, onValidate }) {
  const initialBooleanDataState = {
    sparcCode: false,
    cassiopaeCode: false,
    product: false,
    service: false,
    discount: false,
    volume: false,
    startingDate: false,
    endingDate: false,
  }
  const [data, setData] = useState({
    sparcCode: action === FILE_ACTIONS.addProduct ? selectedFile.sparcCode : '',
    cassiopaeCode: action === FILE_ACTIONS.addProduct ? selectedFile.cassiopaeCode : '',
    product: [FILE_ACTIONS.updateDates, FILE_ACTIONS.updateDiscount].includes(action) ? selectedFile.product.id : '',
    service: action !== FILE_ACTIONS.addFile ? selectedFile.service.id : '',
    discount: [FILE_ACTIONS.updateDates, FILE_ACTIONS.updateDiscount].includes(action) ? selectedFile?.discount : '',
    volume: [FILE_ACTIONS.updateDates, FILE_ACTIONS.updateDiscount].includes(action) ? selectedFile.volume : '',
    startingDate: action === FILE_ACTIONS.updateDates ? selectedFile.startingDate : '',
    endingDate:
      action === FILE_ACTIONS.updateDates
        ? selectedFile.contentiousDate !== null
          ? selectedFile.contentiousDate
          : selectedFile.endingDate
        : '',
  })
  const [products, setProducts] = useState([])
  const [services, setServices] = useState([])
  const [openAlert, setOpenAlert] = useState(false)
  const [disabled, setDisabled] = useState(initialBooleanDataState)
  const [errors, setErrors] = useState(initialBooleanDataState)

  useEffect(async () => {
    const result = await API.queryAPI(servicesQuery)
    setServices(
      action !== 1
        ? result.services
        : Object.entries(SERVICES_ID).map((service) => ({ id: service[1], name: service[0] })),
    )
  }, [])

  useEffect(async () => {
    const products = await getProducts(
      action,
      data.service,
      salesPoint.files,
      selectedFile.sparcCode,
      selectedFile.service?.id,
    )
    setProducts(products)
  }, [data.service])

  useEffect(() => {
    if (action === FILE_ACTIONS.addFile) return
    let keys = []
    if (action === FILE_ACTIONS.addProduct) {
      keys = ['product', 'volume']
      if (selectedFile.service.id === SERVICES_ID.AML) keys.push('discount')
    } else if (action === FILE_ACTIONS.updateDiscount) keys = ['discount']
    else if (action === FILE_ACTIONS.updateDates) keys = ['startingDate', 'endingDate']
    setDisabled({ ...disabled, ...getDisabled(disabled, keys) })
  }, [])

  function _handleChange(name, value) {
    setData({ ...data, [name]: value })
  }

  async function _addNewProduct(queryData) {
    const result = await API.queryAPI(
      addFile({
        ...queryData,
        salesPointId: salesPoint.id,
        errorId: action === 0 ? ERRORS.addFileProduct : ERRORS.addFile,
      }),
    )
    onValidate(
      { ...salesPoint, files: [...salesPoint.files, ...result.addFile] },
      services.find((service) => service.id === data.service).name.substring(0, 3),
    )
  }

  async function _validateDiscount() {
    if (data.discount === '') {
      setErrors({ ...errors, discount: true })
      return
    }
    const result = await API.queryAPI(updateDiscountFileByIds(getFilesIds(selectedFile), data.discount))
    onValidate({
      ...salesPoint,
      files: getUpdatedFiles(
        salesPoint.files,
        result.updateDiscountFileByIds.map((file) => file.id),
        {
          discount: parseInt(data.discount),
          error: result.updateDiscountFileByIds[0].error,
        },
      ),
    })
  }

  async function _validateDates() {
    // Check not empty fields
    const newErrors = { ...errors, startingDateError: !data.startingDate, endingDateError: !data.endingDate }
    setErrors(newErrors)
    if (Object.values(newErrors).includes(true)) return

    const startingDate = getFormatedDate(data.startingDate)
    const endingDate = getFormatedDate(data.endingDate)
    const result = await API.queryAPI(updateDatesFileByIds(getFilesIds(selectedFile), startingDate, endingDate))
    const newFiles = result.updateDatesFileByIds
    onValidate({
      ...salesPoint,
      files: getUpdatedFiles(
        salesPoint.files,
        newFiles.map((file) => file.id),
        {
          startingDate: startingDate,
          endingDate: endingDate,
          error: newFiles[0].error,
        },
      ),
    })
  }

  async function _validateNewProduct() {
    // Check not empty fields
    const newStates = {
      product: !data.product,
      discount: !data.discount && selectedFile.service.name === SERVICES.AML,
      volume: !data.volume,
    }
    setErrors(newStates)
    if (Object.values(newStates).includes(true)) return

    const endingDate = selectedFile.contentiousDate ?? selectedFile.endingDate
    if (checkProductForSelectedDates(data.product, selectedFile.startingDate, endingDate, salesPoint.files)) {
      setOpenAlert(true)
      return
    }

    const discount = disabled.discount
      ? selectedFile.discount
      : action === 0 &&
        (selectedFile.service.name === SERVICES.AMA || selectedFile.service.name.substring(0, 3) === SERVICES.PAD)
      ? 0
      : data.discount

    _addNewProduct({
      ...data,
      discount: discount,
      startingDate: selectedFile.startingDate,
      endingDate: endingDate,
    })
  }

  function _validateAll() {
    const fields = ['service', 'product', 'discount', 'volume', 'startingDate', 'endingDate']
    const newStates = {}
    fields.forEach((field) => {
      !data[field] ? (newStates[field] = true) : (newStates[field] = false)
    })
    setErrors(newStates)
    if (Object.values(newStates).includes(true)) return

    const startingDate = moment(data.startingDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    const endingDate = moment(data.endingDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    if (checkProductForSelectedDates(data.product, startingDate, endingDate, salesPoint.files)) {
      setOpenAlert(true)
      return
    }
    _addNewProduct({ ...data, startingDate: startingDate, endingDate: endingDate })
  }

  function _handleValidate() {
    if (action === FILE_ACTIONS.addProduct) _validateNewProduct()
    else if (action === FILE_ACTIONS.addFile) _validateAll()
    else if (action === FILE_ACTIONS.updateDiscount) _validateDiscount()
    else if (action === FILE_ACTIONS.updateDates) _validateDates()
  }

  return (
    <DialogForm open={open} onValidate={_handleValidate} onClose={onClose} title={title}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AlertForm openAlert={openAlert} onClose={() => setOpenAlert(false)} text="La marque existe déjà !" />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CommonTextField
              name="cassiopaeCode"
              value={disabled.cassiopaeCode ? selectedFile.cassiopaeCode : data.cassiopaeCode}
              onChange={(e) => _handleChange(e.target.name, e.target.value)}
              label="Code dossier Cassiopae"
              disabled={disabled.cassiopaeCode}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CommonTextField
              name="sparcCode"
              value={disabled.sparcCode ? selectedFile.sparcCode : data.sparcCode}
              onChange={(e) => _handleChange(e.target.name, e.target.value)}
              label="Code dossier Sparc"
              disabled={disabled.sparcCode}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name="service"
            value={disabled.service ? selectedFile.service?.id : data.service}
            onChange={(e) => _handleChange(e.target.name, e.target.value)}
            label="Prestation"
            items={services}
            valueName="id"
            text="name"
            disabled={disabled.service}
            required
            error={errors.service}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name="product"
            value={disabled.product && disabled.sparcCode ? selectedFile.product?.id : data.product}
            onChange={(e) => _handleChange(e.target.name, e.target.value)}
            label="Marque"
            items={products}
            valueName="id"
            text="name"
            disabled={disabled.product && disabled.sparcCode}
            required
            error={errors.product}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CommonTextField
            name="discount"
            value={data.discount}
            onChange={(e) => _handleChange(e.target.name, e.target.value)}
            label="€/HL"
            disabled={disabled.discount}
            required
            error={errors.discount}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CommonTextField
            name="volume"
            value={disabled.volume ? selectedFile.volume : data.volume}
            onChange={(e) => _handleChange(e.target.name, e.target.value.replace(',', '.'))}
            label="HL réels (sur la période)"
            disabled={disabled.volume}
            required
            error={errors.volume}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CommonTextField name="amount" value={(data.volume * data.discount).toFixed(2)} label="Montant" disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            name="startingDate"
            value={disabled.startingDate ? selectedFile.startingDate : data.startingDate}
            onChange={(date) => setData({ ...data, startingDate: date })}
            label="Date de début"
            disabled={disabled.startingDate}
            required
            error={errors.startingDate}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            name="endingDate"
            value={
              disabled.endingDate
                ? selectedFile.contentiousDate !== null
                  ? selectedFile.contentiousDate
                  : selectedFile.endingDate
                : data.endingDate
            }
            onChange={(date) => setData({ ...data, endingDate: date })}
            label="Date de fin"
            disabled={disabled.endingDate}
            required
            error={errors.endingDate}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} align="right">
        <WarningMessage text="N'oubliez pas d'effectuer également les actions dans Sparc" />
      </Grid>
    </DialogForm>
  )
}
